.navbar-brand {
	font-family: Diatype, MuseoSans_700, 'Open Sans';
  font-weight: 500;

	color: #fece1a;
}
#nav{
	font-family: Diatype, MuseoSans_700, 'Open Sans';
	overflow: hidden;
	position: absolute;
	z-index: 1;
}
#graph{
  background-image: url('/bg-2.png');
  background-repeat: no-repeat;
  background-size: contain;
	position: absolute;
	z-index: 0;
	overflow: scroll;
}

@font-face {
  font-family: "Diatype";
  src: url("/ABCDiatypeVariable-Trial.woff2");
	font-display: block;
}
/* @font-face {
  font-family: "DomaineDispNar-Bold";
  src: url("/DomaineDispNar-Bold.woff2");
	font-display: swap;
}
@font-face {
  font-family: "DomaineTextWeb-Regular";
  src: url("/DomaineTextWeb-Regular.woff2");
	font-display: swap;
}
@font-face {
  font-family: "Roslindale-Regular";
  src: url("/RoslindaleVariableRegularDefault-VF.woff");
	font-display: swap;
}
@font-face {
  font-family: "Roslindale-Bold";
	src: url("/RoslindaleVariableRegularDefault-VF.woff");
	font-weight: bold;
	font-display: swap	;
}

@font-face {
  font-family: "MuseoSans_700";
  src: url("/MuseoSans_700.woff2");
	font-display: swap;
}
@font-face {
  font-family: "MuseoSans_100";
  src: url("/MuseoSans_100.woff2");
	font-display: swap;
} */
