/* @font-face {
  font-family: "BentonSans Bold";
  src: url("/BentonSans Bold.woff2");
} */
img {max-width:100%;}
body {
  /* background-color: rgba(86,61,124,.05); */
  background-color: #121212;
  overflow-y: scroll;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
#ProjectTitle{
	font-size: 30pt;
  color: #f4f1d0;
	font-family: 'MuseoSans_700',BentonSans Bold, Georgia;

}
p{
	font-size: 1em;
  font-weight: 400;
  color: #f4f1d0;
	font-family: 'Roslindale-Regular', 'DomaineTextWeb-Regular', 'OperatorMono-Book',Georgia, 'Times New Roman', Times, serif;
	line-height: 22px;
  font-optical-sizing: none;

}
a,
a:hover {
	/* font-weight: bold; */
	font-size: 14pt;
  color: #f4f1d0;
	font-family: 'MuseoSans_100','OperatorMono-Book','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
#ImageDescription{
	font-size: .80rem;
	color: #979797;
}
a:hover {
  text-decoration: none;
}

.block {
  height: 350px;
  background: rgba(86,61,124,.15);
  margin-bottom: 30px;
}

.label {
  font-size: .85rem;
  color:#f4f1d0;

  /* font-weight: bold; */
}
#description{
  color:#f4f1d0;
	font-size: 12pt;
}
